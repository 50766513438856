@import url('https://fonts.googleapis.com/css?family=Roboto:400,Roboto:900&display=swap');
body {
  font-family: 'Roboto', sans-serif!important;
}

h1 {
  font-size: 56px!important;
}

.center {
  text-align: center;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.main-nav {
  background: #202021!important;
  height: 60px!important;
}

.logo {
  height: 35px;
  margin-left: 65px;
}

.navbar-menu {
  margin-left: 161px!important;
}

.navbar-item {
  font-family: 'Roboto', sans-serif!important;
  font-weight: 700;
  color: #fff!important;
  font-size: 14px!important;
  font-weight: bold!important;
  font-style: normal!important;
  font-stretch: normal!important;
  line-height: 1.33!important;
  letter-spacing: 0.5px!important;
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-item:hover {
  color: #000!important;
}

.g-button {
  position: relative !important;
  width: 205px;
  right: -12px !important;
  border: 0 !important;
  background: #ffbd27;
  height: 60px;
  margin: 0;
  padding-top: 17px;
  top: -4px;
  color: #202021;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.demo-button {
  border: 0 !important;
  background: #f6f6f6;
  height: 60px;
  margin: 0;
  padding-top: 17px;
  top: -4px;
  color: #202021;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 0.5px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  margin-right: -12px;
}

.navbar-burger span {
  background-color: #ffbd27!important;
}

.hero-main {
  width: 100%;
  margin-top: -10px;
  background: #121212;
}

#wave {
  height: 100%;
  width: 100%;
  background: url('./assets/Wave.png'), #282828;
}

.hero-img {
  max-width: 100%;
  padding-top: 45px;
}

.hero-nav {
  background: transparent!important;
  padding-left: 20px;
  z-index: 1!important;
}

.hero-nav a {
  font-size: 1rem!important;
  color: #fff!important;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.hero-nav a:hover {
  color: #ffbd27!important;
}

.hero-content {
  margin-left: 30px;
}

.hero-content {
  color: #fff;
}

.hero-content div h1 {
  line-height: 60px;
  letter-spacing: -1px;
  color: #ffffff;
  font-weight: 900;
  font-size: 44px!important;
}

#email-addy {
  width: 304px;
  height: 48px;
  background-color: #202021;
  border: none;
  color: #fff;
  padding: 10px;
}

.hero-get-started {
  width: 155px;
  height: 48px;
  background-color: #ffbd27;
  border: none;
  cursor: pointer;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.custom-modal {
  height: 100vh;
  width: 100vw;
  opacity: 0.95;
  background: #000;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: scroll;
}

.custom-modal div {
  padding: 20%;
  text-align: center;
}

.custom-modal div h1 {
  font-size: 44px;
  margin-bottom: 10px;
}

.custom-modal div h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.custom-modal div span {
  float: right;
  font-size: 24px;
  font-weight: 800;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.download-btn {
  width: 155px;
  height: 48px;
  background-color: #ffbd27;
  border: none;
  cursor: pointer;
}

#social-proof {
  width: 100%;
  background-color: #f6f6f6;
}

.banner ul {
  list-style-type: none;
  text-align: center;
  padding-top: 70px;
}

.banner ul li {
  display: inline;
  padding: 20px;
}

.social-proof-img {
  width: 15%;
  object-fit: contain;
  margin-bottom: 40px;
}

.icon-div img {
  position: relative;
  top: 0;
  left: 45%;
  width: 5%;
  margin-top: 65px;
}

.product-info {
  max-width: 50%;
  margin: auto;
}

.product-info h1 {
  letter-spacing: -1.0px;
  line-height: 60px;
  font-weight: 900;
  margin-top: 25px;
}

.product-info p {
  margin-top: 30px;
}

#product-demo {
  max-width: 65%;
  margin: auto;
  margin-top: 75px;
  text-align: center;
}

.product-rect {
  position: absolute;
  width: 100vw;
  height: 100px;
  z-index: -1;
  background: #FFBD27;
  margin-top: 300px;
}

.product-nav {
  margin-top: 40px;
  background: transparent!important;
  z-index: 1!important;
}

.product-nav .navbar-brand {
  max-width:  85%;
  margin: auto;
}

.product-nav p {
  font-size: 1rem!important;
  color: #cccccc!important;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.product-nav a:hover {
  color: #ffbd27!important;
}

.product-features {
  max-width: 85%;
  margin: auto;
  margin-top: 80px;
}

.icon-img {
  width: 55px!important;
  height: 55px!important;
}

.detailed-product {
  background: #F6F6F6;
  margin-top: 200px;
  padding-bottom: 200px;
}

.testimonial-examples {
  padding-top: 200px;
  text-align: center;
}

.github-box {
  background: url(./assets/Wave.png);
  position: absolute;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: -100px;
  height: 200px;
  background-color: #000;
  padding-top: 20px;
}

.github-box div {
  padding: 2%;
  color: #fff;
}

.github-box div div div p a {
  color: #FFBD27;
}

.github-stars {
  width: 220px;
  height: 60px;
  background-color: #ffbd27;
  text-align: center;
  color: #121212!important;
}

.github-stars div {
  position: relative;
  top: 3px;
  color: #121212!important;
}

.github-stars div a {
  color: #121212;
}

.details-one {
  text-align: center;
  padding-top: 200px;
  max-width: 60%;
  margin: auto;
}

.details-one h1 {
  margin-top: 23px;
  margin-bottom: 30px;
}

.details-two {
  padding-top: 300px;
}

.details-two div div div h2, .details-three div div div h2 {
  font-size: 34px!important;
  font-weight: 900;
}

.orange-circle {
  height: 500px;
  width: 500px;
  background: #FFBD27;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  margin-top: -200px;
}

.details-three {
  margin-top: 250px;
}

.black-circle {
  height: 500px;
  width: 500px;
  background: #121212;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  margin-top: -200px;
  margin-left: 400px;
}

.docs-gif {
  border-radius: 8px;
  margin-left: 25px;
}

.enterprise-summary {
  padding-top: 200px;
  max-width: 60%;
  margin: auto;
}

.enterprise-summary h1 {
  font-size: 56px;
}

.teams-image-section {
  max-width: 90%;
  margin: auto;
  text-align: center;
  margin-top: 60px;
}

.orange-bar {
  width: 100%;
  position: absolute;
  height: 100px;
  background: #FFBD27;
  margin-top: -10px;
}

.enterprise-features {
  text-align: center;
  padding-top: 200px;
  max-width: 85%;
  margin: auto!important;
}

.enterprise-features div h3 {
  font-size: 25px;
  font-weight: 900;

}

.arrow-col img {
  position: relative;
  top: 35%;
}

.testimonials-main {
  padding-bottom: 100px;
  background: #F6F6F6;
  margin-top: 200px;
}

.testimonial img {
  margin-bottom: 40px;
}

.testimonial p {
  font-size: 24px;
  font-weight: 900;
}

.foot {
  position: relative;
  width: 100%;
  height: auto;
  background: url('./assets/Wave.png'), #121212;
}

.footer-cols {
  max-width: 90%;
  margin: auto!important;
  padding-top: 40px;
}

.footer-cols div h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 900;
}

.footer-cols div p, .footer-cols div p a {
  color: #fff;
}

.footer-logo {
  max-width: 50%!important;
  margin: auto!important;
}

.footer-cols div ul {
  list-style: none;
}

.footer-cols div li {
  display: inline;
  margin-right: 8px;
  margin-top: 5px;
}

.demo-form {
  max-width: 100%;
  margin: auto;
}

.demo-form input, .demo-form textarea {
  border-radius: 5px;
  border: none;
  padding: 5px;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.submit-demo {
  height: 60px;
  width: 200px;
  background: #FFBD27;
  border: none;
  cursor: pointer;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.doc-ex {
  border: 1px solid #121212!important;
  border-radius: 8px!important;
}

.about-content p {
  margin-top: 20px;
  font-size: 18px;
}

.about-content p a  {
  color: #FFBD27;
}

.faq-section h3 {
  font-size: 24px;
}

.faq-section p {
  margin-bottom: 30px;
}

.team-images {
  max-width: 50%;
  border-radius: 5px;
}

#team .columns {
  margin-top: 15px;
  margin-bottom: 15px;
}

#team .columns .column h3 {
  font-size: 20px;
}

#labs-section {
  padding-top: 170px;
}

#labs-examples {
  margin-top: 40px;
  text-align: center;
}

#labs-examples .columns .column a {
  color: #282828;
}

#labs-examples .columns .column a h3 {
  font-size: 20px;
}

.labs-rect {
  position: absolute;
  width: 100vw;
  height: 75px;
  z-index: -1;
  background: #FFBD27;
  margin-top: 100px;
}

.notification-banner {
  position: fixed;
  top: 60px;
  width: 100%;
  left: 0;
  background: yellow;
  padding: 20px;
  text-align: center;
  z-index: 1024;
}

/* Media Queries */

@media only screen and (max-width: 1600px) {
  .black-circle {
    height: 500px;
    width: 500px;
    background: #121212;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    margin-top: -200px;
    margin-left: 200px;
  }
}

@media only screen and (max-width: 1300px) {
  .navbar-item {
    font-size: 12px!important;
  }

  .g-button {
    font-size: 12px!important;
    width: 160px!important;
  }
  
  .demo-button {
    font-size: 12px!important;
  }

  .black-circle {
    height: 500px;
    width: 500px;
    background: #121212;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    margin-top: -200px;
    margin-left: 150px;
  }
}

@media only screen and (max-width: 1150px) {
  .navbar-menu {
    margin-left: 100px!important;
  }

  .hero-content div h1 {
    font-size: 20px!important;
  }

  .product-info h1 {
    font-size: 36px!important;
  }

  .hero-img {
    max-width: 75%;
    padding-top: 45px;
    margin: auto;
  }

  .product-rect {
    position: absolute;
    width: 100vw;
    height: 75px;
    z-index: -1;
    background: #FFBD27;
    margin-top: 250px;
  }

  .black-circle {
    height: 500px;
    width: 500px;
    background: #121212;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    margin-top: -200px;
    margin-left: 100px;
  }
}

@media only screen and (max-width: 1000px) {
  .orange-circle {
    height: 500px;
    width: 500px;
    background: #FFBD27;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    margin-top: -100px;
  }

  .orange-column {
    margin-top: 200px;
    margin-left: 100px;
  }
}
@media only screen and (max-width: 760px) {
  #wave {
    height: auto;
  }
  #labs-section div h1 {
    font-size: 25px!important;
  }
  .icon-div img {
    max-width: 15%;
    margin: auto;
    margin-top: 60px;
  }
  .hero-img {
    max-width: 50%;
    padding-top: 45px;
    margin: auto;
  }

  .product-nav {
    display: none;
  }

  .product-rect {
    position: absolute;
    width: 100vw;
    height: 60px;
    z-index: -1;
    background: #FFBD27;
    margin-top: 200px;
  }

  .product-features {
    margin-top: 60px;
  }

  div.product-features div div h3 {
    font-size: 26px!important;
  }

}

@media only screen and (max-width: 600px) {
  .logo {
    margin-left: 10px;
  }

  .product-rect {
    position: absolute;
    width: 100vw;
    height: 50px;
    z-index: -1;
    background: #FFBD27;
    margin-top: 150px;
  }

  .custom-modal div {
    padding: 0!important;
    text-align: center;
    margin-top: 20px!important;
  }

  .details-one h1, .enterprise-summary h1 {
    font-size: 36px!important;
  }
}

@media only screen and (max-width: 1020px) {
  .logo {
    margin-left: 10px;
  }
  .main-nav {
    background: #202021!important;
    height: 50px!important;
  }

  .navbar-item {
    color: #202021!important;
  }
  #navbarBasicExample {
    width: 300px!important;
    float: right!important;
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  .fade-out {
    opacity: 0;
    animation-name: fadeOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }
  
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}